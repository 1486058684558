
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-content {
  padding: 42px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-layout-content {
  background: #F9F9FB !important;
}

.container {
  min-height: 900px;
  background-color: #F9F9FB;
  /* padding: 24px 48px; */
  padding: 12px 24px;
}
.breadcrumb {
  padding: 0 !important;
}
.breadcrumb .ant-breadcrumb-separator .anticon-right {
  color: #4F95DA !important;
}

.ant-form-item .ant-form-item-label>label {
  color: #4F95DA !important; 
}

.studyDetailsImg {
  width: 24px;
  max-width: 24px;
  min-width: 20px;
}

 .downloadFloatBtn {
   width: 100%;
   position: fixed;
   /* left: 660; */
   bottom: 0;
   z-index: 999;
   background: #F1F7FD !important;
   padding: 10px 0;
 }

 .downloadFloatBtn .ant-btn {
   display: inline-block;
 }

 .downloadFloatBtn .ant-btn>div {
   line-height: 12px;
 }


@media (min-width: 1536px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 1280px) {
  /* >=1280的设备 */
  .formItemsAdd .ant-col-4 {
    margin-bottom: 12px !important;
  }
  .floatBtn .floatBtnBox{
    /* margin-left: 35% !important; */
    display: flex;
      justify-content: center;
      margin-left: -180px;
  }
  .downloadFloatBtn .downloadBtns {
    display: flex;
    justify-content: center;
    margin-left: -232px;
  }
        
}
@media screen and (min-width:1024px) and (max-width:1279px) {
  /* >=1024 <=1279的设备 */
  .formItemsAdd .ant-col-4 {
    margin-bottom: 12px !important;
  }
  .floatBtn .floatBtnBox {
    /* margin-left: 35% !important; */
    display: flex;
    justify-content: center;
    margin-left: -180px;
  }
  .downloadFloatBtn .downloadBtns {
    display: flex;
    justify-content: center;
    margin-left: -232px;
  }
    
}

@media screen and (min-width:768px) and (max-width:1023px) {
  /* >=768 <=1023的设备 */
  .container {
    /* max-width: 100% !important; */
  }
  .ant-form-item .ant-form-item-label>label {
    font-size: 18px !important;
    color: #4F95DA !important;
  }
  .formItemsAdd .ant-col-4 {
    margin-bottom: 12px !important;
  }
  .floatBtn .floatBtnBox {
    /* margin-left: 35% !important; */
    display: flex;
    justify-content: center;
  }
  .downloadFloatBtn .downloadBtns {
    display: flex;
    justify-content: center;
    /* margin-left: -232px; */
  }
        
}
@media (max-width: 767px) {
  /* <=767的设备  */
  /* .container {
    max-width: 100% !important;
  } */
  .ant-table-wrapper .ant-table-thead>tr>th {
      font-size: 10px !important;
  }
  .ant-form-item .ant-form-item-label>label{
    font-size: 10px !important;
    color: #4F95DA !important;
  }
  .floatBtn .floatBtnBox {
    /* margin-left: 35% !important; */
    display: flex;
    justify-content: center;
  }
  .downloadFloatBtn .downloadBtns {
    display: flex;
    justify-content: center;
    /* margin-left: -232px; */
  }
  
}
@media (min-width: 640px) {
  .container {
    max-width: 100% !important;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100% !important;
  }
}


/* @media (min-width: 768px) {
  .container {
    max-width: 100% !important;
  }
} */
/* @media (min-width: 640px) {
  .container {
    max-width: 100% !important;
  }
} */

/* 弹框 */
/* .ant-modal-root .ant-modal-wrap{
  z-index: 99999 !important;
} */
/* input select框 border */
.ant-tooltip .ant-tooltip-inner {
  padding: 10px !important;
}

.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border-color: #0F95DB !important;
}

.ant-select:hover .ant-select-selector {
  border-color: #0F95DB !important;
}

.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #0F95DB !important;
  box-shadow: 0 0 0 2px rgba(16, 124, 65, 0.1) !important;
}

/* 按钮 */
.ant-btn {
  font-family: "Open Sans", serif !important;
  border-radius: 6px !important;
  font-weight: bold !important;
}

.fromBtn .ant-btn {
  width: 280px;
  height: 42px;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  background: #FFFFFF;
  border-radius: 30px 30px 30px 30px;
  border: 1px solid #CED4DB;
}

.ant-btn-primary {
  background: #4F95DA !important;
  color: #FFFFFF !important;
}

.fromBtnStatus .ant-btn-primary {
  background: #cccccc !important;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background: #4F95DA;
  color: #FFFFFF;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: #FFFFFF;
  color: #333333;
  border: 1px solid #CED4DB;
}

.noDataBtn {
  text-align: center;
  margin-top: 18px;
}

.noDataBtn .ant-btn {
  font-size: 18px !important;
  height: 60px !important;
}

/* 下拉 */

.ant-select-dropdown {
  padding: 4px 0 !important;
}

.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #E5F1FC !important;
}

.ant-select-selection-item img {
  display: none;
}

/*  */
.ant-spin .ant-spin-dot-holder {
  color: #4F95DA !important;
}

.ant-popover-content {
  width: 140px !important;
}

/* .ant-btn-sm {
  border-radius: 14px !important;
} */

.ant-spin-nested-loading >div>.ant-spin .ant-spin-dot{
  position: fixed !important;
  inset-inline-start: 57.6% !important;
}

.ant-* {
  font-family: "Open Sans", serif !important;
}