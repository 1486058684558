@tailwind base;
@tailwind components;
@tailwind utilities;
@font-family:"Open Sans",serif

body{
  margin: 0;
  padding: 0;
  font-family: "Open Sans",serif !important;
}

html{
  font-family: "Open Sans", serif !important;
}

.navigation_top{
  padding: 0 0 20px;
  
  background: #F9F9FB !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
 .ant-input,
.login-form-forgot,
.ant-table,
.ant-layout h5,
.ant-list,
.headerLogout,
.ant-modal-body,
.ant-select-single .ant-select-selector,
.ant-popconfirm-description,
.ant-typography{
  font-family: "Open Sans", serif !important;
}


body,
html {
  width: 100%;
  height: 100%;
}

.poppins-thin {
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
/* .ant-menu-title-content, */
.poppins-semibold,
.ant-layout h3,
.previewTitle,
.ant-form-item label,
.ant-popconfirm-title,
.ant-modal-header .ant-modal-title{
  font-family: "Open Sans", serif; 
  font-weight: bold;
  font-style: normal;
}


.poppins-bold {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", serif;
  font-weight: bold;
  font-style: italic;
}
.ant-modal-header .ant-modal-title{
  color: #4F95DA !important;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  /* display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1; */
  /* content: "*"; */
  content: ' '
}

.ant-form-item .ant-form-item-label>label::after {
  content: "*";
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

@font-face {
  font-family: 'Colonna MT';
  src: url('./static/fonts/Colonna-MT-2.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Racing Sans One';
  src: url('./static/fonts/RacingSansOne-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri normal';
  src: url('./static/fonts/calibri.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('./static/fonts/calibri-bold.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri italic';
  src: url('./static/fonts/Calibri-Light-Italic.TTF');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'American Typewriter';
  src: url('./static/fonts/American-Typewriter-Condensed-Bold.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PingFangSC-Regular';
  src: url('./static/fonts/PingFangSC-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PingFangSC-Semibold';
  src: url('./static/fonts/PingFangSC-Semibold.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* 整个滚动条 */
div::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* 滚动条有滑块的轨道部分 */
div::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 5px;
}

/* 滚动条滑块(竖向:vertical 横向:horizontal) */
div::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #f2f2f2;
  border-radius: 5px;
}

/* 滚动条滑块hover */
div::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

/* 同时有垂直和水平滚动条时交汇的部分 */
div::-webkit-scrollbar-corner {
  display: block;
  /* 修复交汇时出现的白块 */
}